import api from "../../configuration/Api";

/**
 * Service permettant de gérer la galerie.
 */
class GalleryService {

    private apiUri = "/gallery";

    async getAllImages() {

        return api.get(`${this.apiUri}/all`).then((response) => {

            return response.data;
        });
    }

    async addImages(parameters: FormData) {

        return api.post(`${this.apiUri}/add-images`, parameters)
            .then((response) => {

                return response.data;
            });
    }
}

export const galleryService = new GalleryService();