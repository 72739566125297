import SendFormButton from "../SendFormButton/SendFormButton";
import React, {FormEvent, useEffect, useState} from "react";
import TextInput from "../TextInput/TextInput";
import TextArea from "../TextArea/TextArea";
import DatePicker from "../DatePicker/DatePicker";
import ImageSelector from "../ImageSelector/ImageSelector";
import ImagePrevisualizer from "../ImagePrevisualizer/ImagePrevisualizer";
import {memoryService} from "../../service/Memory/MemoryService";
import store from "../../store/Store";
import {
    closeMemoryCreationDialog,
    selectIsMemoryFormDialogOpen,
    selectMemories, selectSelectedMemory,
    setMemories
} from "../../store/Memory/MemorySlice";
import {useSelector} from "react-redux";
import {Memory} from "../../model/Memory/Memory";
import {imageService} from "../../service/ImageService/ImageService";

/**
 * Composant permettant de représenter la dialog d'ajout
 * de souvenirs.
 *
 * @constructor
 */
const MemoryFormDialog = () => {

    const [formState, setFormState] = useState({
        title: "",
        description: "",
        date: "",
        images: [] as File[],
    });

    const [titleValid, setTitleValid] = useState(true);
    const [dateValid, setDateValid] = useState(true);
    const [imagesValid, setImagesValid] = useState(true);

    const [isSending, setIsSending] = useState(false);

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const isOpen = useSelector(selectIsMemoryFormDialogOpen);

    const memory = useSelector(selectSelectedMemory);
    const memories = useSelector(selectMemories);

    useEffect(() => {

        if (!memory) {

            return;
        }

        setFormState({
            title: memory.title,
            description: memory.description,
            date: getMemoryDate(memory),
            images: [],
        });

        const fetchImages = async () => {

            const imagesFiles = await getMemoryImagesFiles(memory);
            setFormState(state => ({...state, images: imagesFiles}));
        };

        fetchImages();

    }, [memory]);

    const handleInputChange = (field: string, value: any) => {
        setFormState({...formState, [field]: value});
    };

    /**
     * Méthode permettant de récupérer la date
     * d'un souvenir.
     *
     * @param memory souvenir pour lequel récupérer
     * la date.
     */
    const getMemoryDate = (memory: Memory): string => {

        if (memory.date) {

            return new Date(memory.date).toLocaleDateString();
        }

        return "";
    };

    /**
     * Méthode permettant de récupérer les fichiers d'images
     * d'un souvenir.
     *
     * @param memory souvenir pour lequel récupérer les
     * fichiers d'image.
     */
    const getMemoryImagesFiles = async (memory: Memory): Promise<File[]> => {

        const files: File[] = [];

        for (let image of memory.images) {

            const imageBlob = await imageService.getImageBlob(image.fileName);

            const file = new File([imageBlob], image.fileName, {type: imageBlob.type});

            files.push(file);
        }

        return files;
    };

    /**
     * Méthode permettant de fermer la dialog.
     */
    const handleClosing = () => {

        store.dispatch(
            closeMemoryCreationDialog()
        );

        resetFormContent();
        resetFormValidation();
        setIsSending(false);
    };

    /**
     * Méthode permettant de gérer les erreurs.
     * @param error erreur à gérer.
     */
    const handleError = () => {

        setErrorMessage("Une erreur est survenue, veuillez réessayer.");
        setShowErrorMessage(true);
    }

    /**
     * Méthode permettant de gérer l'ajout
     * ou la modification d'un souvenir.
     *
     * @param event événement concernant le formulaire d'ajout
     * du souvenir.
     */
    const handleSendMemoryForm = (event: FormEvent) => {

        event.preventDefault();

        const isFormValid = validateForm();

        if (isFormValid) {

            setIsSending(true);

            const formData = new FormData();
            formData.append("title", formState.title);
            formData.append("description", formState.description);
            formData.append("date", formState.date);

            for (let image of formState.images) {

                formData.append("imagesFiles", image);
            }

            if (memory) {

                formData.append("id", memory.id.toString());

                updateMemory(formData);
            } else {
                createMemory(formData);
            }
        }
    };

    /**
     * Méthode permettant de mettre à jour un souvenir.
     *
     * @param parameters paramètres de mise à jour du souvenir.
     */
    const updateMemory = (parameters: FormData) => {

        memoryService.updateMemory(parameters)// TODO : concaténer avec la création.
            .then((response: Memory) => {

                const updatedMemoryId = response.id;

                const updatedMemories = memories.filter(memory => memory.id !== updatedMemoryId);

                const conatenatedMemories = [
                    ...updatedMemories,
                    response
                ];

                const sortedMemories = conatenatedMemories.sort((a, b) => {

                    return (new Date(b.date)).getTime() - (new Date(a.date)).getTime();
                });

                store.dispatch(
                    setMemories(sortedMemories)
                );

                handleClosing();
            })
            .catch(() => {

                resetFormValidation();
                setIsSending(false);
                handleError();
            });
    }

    /**
     * Méthode permettant de créer un souvenir.
     *
     * @param parameters paramètres de création du souvenir.
     */
    const createMemory = (parameters: FormData) => {

        memoryService.createMemory(parameters)
            .then((response: Memory) => {

                const conatenatedMemories = [
                    ...memories,
                    response
                ];

                const sortedMemories = conatenatedMemories.sort((a, b) => {

                    return (new Date(b.date)).getTime() - (new Date(a.date)).getTime();
                });

                store.dispatch(
                    setMemories(sortedMemories)
                );

                handleClosing();
            })
            .catch(() => {

                resetFormValidation();
                setIsSending(false);
                handleError();
            });
    };

    /**
     * Méthode permettant de réinitialiser le contenu
     * du formulaire.
     */
    const resetFormContent = () => {

        setFormState({
            title: "",
            description: "",
            date: "",
            images: [],
        });
    };

    /**
     * Méthode permettant de réinitialiser la validation
     * du formulaire.
     */
    const resetFormValidation = () => {

        setTitleValid(true);
        setDateValid(true);
        setImagesValid(true);
    };

    /**
     * Méthode permettant de valider le formulaire
     * d'ajout d'un souvenir.
     */
    const validateForm = () => {

        let isValid = true;

        if (isStringEmpty(formState.title)) {

            setTitleValid(false);
            isValid = false;
        }

        if (isStringEmpty(formState.date)) {

            setDateValid(false);
            isValid = false;
        }

        if (isImagesEmpty(formState.images)) {

            setImagesValid(false);
            isValid = false;
        }

        return isValid;
    };

    /**
     * Méthode permettant de savoir si des images ont
     * été sélectionnées ou non.
     *
     * @param images les images à vérifier.
     */
    const isImagesEmpty = (images: File[]) => {

        return images === null || images.length === 0;
    };

    /**
     * Méthode permettant de savoir si une chaîne de
     * caractère est vide ou non.
     *
     * @param string la chaîne de caractère à vérifier.
     */
    const isStringEmpty = (string: string) => {

        return !string || string === "";
    }

    /**
     * Méthode permettant de gérer ce qui se passe à la suppression d'une
     * image dans le visualiseur.
     *
     * @param index index de l'image concernée par la suppression.
     */
    const handleImageDeletion = (index: number): void => {

        setFormState(state => ({
            ...state,
            images: state.images.filter((_, i) => i !== index)
        }));
    };

    /**
     * Méthode permettant de récupérer dynamiquement le
     * titre de la dialog.
     */
    const getMemoryDialogTitle = (): string => {

        if (memory) {

            return "Mettre à jour un souvenir";
        }

        return "Ajouter un souvenir";
    }

    /**
     * Méthode permettant de récupérer dynamiquement le
     * titre du bouton d'envoi du formulaire.
     */
    const getFormButtonSendingTitle = (): string => {

        if (memory) {

            return "Sauvegarder";
        }

        return "Ajouter";
    }

    return (<div>{
        isOpen &&
        <div className="fixed inset-0 z-50 flex flex-col justify-center p-6 bg-white md:items-center">

            <div className="md:w-1/2 2xl:w-1/3">

                <div className="flex justify-between items-center mb-4">

                    <h2 className="text-2xl font-semibold">{ getMemoryDialogTitle() }</h2>
                </div>

                {
                    showErrorMessage &&
                    <div className="border rounded-md border-red-500 bg-red-100 p-2 text-red-600">
                        {errorMessage}
                    </div>
                }

                <div className="flex flex-col space-y-2">

                    <TextInput label="Titre du souvenir*" value={formState.title}
                               onChange={(value: string) => handleInputChange("title", value)} valid={titleValid}/>
                    <DatePicker label="Date du souvenir*" value={formState.date}
                                onChange={(value: string) => handleInputChange("date", value)} valid={dateValid}/>
                    <TextArea label="Description du souvenir" value={formState.description}
                              onChange={(value: string) => handleInputChange("description", value)} valid={true}/>
                    <ImageSelector label="Images*" value={formState.images}
                                   onChange={(files: File[]) => handleInputChange("images", files)} valid={imagesValid}/>
                    <ImagePrevisualizer images={formState.images} onImageDeletion={handleImageDeletion}/>
                </div>

                <p className="text-xs text-gray-500">Les champs sont obligatoires*</p>

                <div className="mt-4 flex justify-end space-x-2">
                    <SendFormButton onClick={handleSendMemoryForm} label={getFormButtonSendingTitle()} sending={isSending}/>
                    <button className="border rounded-md py-2 px-4 hover:border-gray-300 hover:bg-gray-50"
                            onClick={handleClosing}>Annuler
                    </button>
                </div>
            </div>
        </div>
    }</div>);
};

export default MemoryFormDialog;