import {FormEvent, MouseEventHandler, useState} from "react";
import {userService} from "../../service/User/UserService";
import {useNavigate} from "react-router-dom";
import TextInput from "../TextInput/TextInput";
import SendFormButton from "../SendFormButton/SendFormButton";

type LoginFormProps = {
    onResetPassword: MouseEventHandler<HTMLButtonElement>,
};

/**
 * Composant permettant de représenter le
 * formulaire de connexion à l'application.
 *
 * @param props props du composant.
 * @constructor
 */
const LoginForm = (props: LoginFormProps) => {

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");

    const [loginValid, setLoginValid] = useState(true);
    const [passwordValid, setPasswordValid] = useState(true);

    const [isSent, setIsSent] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate();

    /**
     * Méthode permettant de gérer la connexion
     * de l'utilisateur.
     *
     * @param event événement concernant l'envoi du formulaire.
     */
    const handleLogin = (event: FormEvent) => {

        event.preventDefault();

        const isFormValid = validateForm();

        if (isFormValid) {

            setIsSending(true);

            userService.loginUser({login: login, password: password})
                .then(() => {

                    setLoginValid(true);
                    setPasswordValid(true);
                    setIsSending(false);
                    setIsSent(true);
                    navigate("/memories");
                })
                .catch((error) => {

                    setLoginValid(true);
                    setPasswordValid(true);
                    setIsSending(false);
                    setIsSent(false);
                    handleError(error);
                });
        }
    }

    const handleError = (error: any) => {

        if (error.status === 401) {

            setErrorMessage("Identifiant ou mot de passe incorrect.");
        } else {

            setErrorMessage("Une erreur est survenue, veuillez réessayer.");
        }

        setShowErrorMessage(true);
    }

    /**
     * Méthode permettant de valider le formulaire
     * de connexion.
     */
    const validateForm = () => {

        let isValid = true;

        if (isStringEmpty(login)) {

            setLoginValid(false);
            isValid = false;
        }

        if (isStringEmpty(password)) {

            setPasswordValid(false);
            isValid = false;
        }

        return isValid;
    };

    /**
     * Méthode permettant de savoir si une chaîne de
     * caractère est vide ou non.
     *
     * @param string la chaîne de caractère à vérifier.
     */
    const isStringEmpty = (string: string) => {

        return !string || string === "";
    }

    return (
        <form className="flex flex-col space-y-4 font-raleway">

            {
                showErrorMessage &&
                <div className="border rounded-md border-red-500 bg-red-100 p-2 text-red-600">
                    {errorMessage}
                </div>
            }

            <h2 className="text-2xl font-medium">Se connecter</h2>

            <TextInput label="Nom d'utilisateur ou adresse mail*" value={login} onChange={setLogin} valid={loginValid}/>
            <TextInput label="Mot de passe*" value={password} onChange={setPassword} valid={passwordValid}
                       type="password"/>

            <div className="flex justify-end">

                <button type="button" className="hover:cursor-pointer hover:underline"
                        onClick={props.onResetPassword}>Mot de passe oublié ?
                </button>
            </div>

            <p className="text-xs text-gray-500">Les champs sont obligatoires*</p>

            <div className="flex justify-end pt-4">

                <SendFormButton onClick={handleLogin} label="Se connecter" sent={isSent} sending={isSending}/>
            </div>
        </form>
    );
};

export default LoginForm;