import {FormEvent, MouseEventHandler, useState} from "react";
import TextInput from "../TextInput/TextInput";
import SendFormButton from "../SendFormButton/SendFormButton";
import {userService} from "../../service/User/UserService";
import ValidationCodeInput from "../ValidationCodeInput/ValidationCodeInput";

type ResetPasswordFormProps = {
    onLogin: MouseEventHandler<HTMLButtonElement>,
};

/**
 * Composant permettant de représenter le formulaire
 * de demande de réinitialisation de mot de passe.
 *
 * @param props props du composant.
 * @constructor
 */
const ResetPasswordForm = (props: ResetPasswordFormProps) => {

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [confirmationPassword, setConfirmationPassword] = useState("");
    const [validationCode, setValidationCode] = useState("");

    const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);

    const [loginValid, setLoginValid] = useState(true);
    const [passwordValid, setPasswordValid] = useState(true);
    const [confirmationPasswordValid, setConfirmationPasswordValid] = useState(true);
    const [validationCodeValid, setValidationCodeValid] = useState(true);

    const [isSending, setIsSending] = useState(false);

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    /**
     * Méthode permettant de gérer la demande de réinitialisation
     * de mot de passe.
     *
     * @param event événement concernant l'envoi du formulaire.
     */
    const handleResetPassword = (event: FormEvent) => {

        event.preventDefault();

        if (!showResetPasswordForm) {

            sendResetPasswordRequestForm();
        } else {

            sendResetPasswordForm();
        }
    };

    /**
     * Méthode permettant d'envoyer le formulaire
     * de réinitialisation de mot de passe.
     */
    const sendResetPasswordForm = () => {

        const isFormValid = validateResetPasswordForm();

        if (isFormValid) {

            setIsSending(true);

            userService.resetPassword(login, password, confirmationPassword, Number(validationCode))
                .then(() => {

                    resetFormContent();
                    resetFormValidation();
                    setIsSending(false);
                    setShowResetPasswordForm(false);
                })
                .catch((error) => {

                    resetFormValidation();
                    setIsSending(false);
                    handleError(error);
                });
        }
    };

    const resetFormContent = () => {

        setLogin("");
        setPassword("");
        setConfirmationPassword("");
        setValidationCode("");
    };

    const resetFormValidation = () => {

        setLoginValid(true);
        setPasswordValid(true);
        setConfirmationPasswordValid(true);
        setValidationCodeValid(true);
    }

    /**
     * Méthode permettant d'envoyer le formulaire de demande
     * de réinitialisation de mot de passage.
     */
    const sendResetPasswordRequestForm = () => {

        const isFormValid = validateResetPasswordRequestForm();

        if (isFormValid) {

            setIsSending(true);

            userService.requestPasswordReset(login)
                .then(() => {

                    resetFormValidation();
                    setIsSending(false);
                    setShowResetPasswordForm(true);
                })
                .catch((error) => {

                    resetFormValidation();
                    setIsSending(false);
                    handleError(error);
                });
        }
    };

    const handleError = (error: any) => {

        // TODO : terminer.

        setShowErrorMessage(true);
    }

    /**
     * Méthode permettant de valider le formulaire
     * de demande de réinitialisation de mot de passe.
     */
    const validateResetPasswordRequestForm = () => {

        let isValid = true;

        if (isStringEmpty(login)) {

            setLoginValid(false);
            isValid = false;
        }

        return isValid;
    };

    /**
     * Méthode permettant de valider le formulaire
     * de réinitialisation de mot de passe.
     */
    const validateResetPasswordForm = () => {

        let isValid = true;

        if (isStringEmpty(validationCode) && validationCode.length < 6) {

            setValidationCodeValid(false);
            isValid = false;
        }

        if (isStringEmpty(password)) {

            setPasswordValid(false);
            isValid = false;
        }

        if (isStringEmpty(confirmationPassword)) {

            setConfirmationPasswordValid(false);
            isValid = false;
        }

        if (password !== confirmationPassword) {

            isValid = false;
            setPasswordValid(false);
            setConfirmationPasswordValid(false);
            setErrorMessage("Les mots de passe ne correspondent pas.");
            setShowErrorMessage(true);
        }

        return isValid;
    }

    /**
     * Méthode permettant de savoir si une chaîne de
     * caractère est vide ou non.
     *
     * @param string la chaîne de caractère à vérifier.
     */
    const isStringEmpty = (string: string) => {

        return !string || string === "";
    }

    return (
        <form className="flex flex-col space-y-4 font-raleway">

        {
            showErrorMessage &&
            <div className="border rounded-md border-red-500 bg-red-100 p-2 text-red-600">
                { errorMessage }
            </div>
        }

        <h2 className="text-2xl font-medium">Mot de passe oublié ?</h2>

        {
            !showResetPasswordForm && <TextInput label="Nom d'utilisateur ou adresse mail*" value={ login } onChange={ setLogin } valid={ loginValid } />
        }

        {
            showResetPasswordForm &&
            <div className="space-y-4">
                <ValidationCodeInput label="Code de validation*" value={ validationCode } onComplete={ setValidationCode } valid={ validationCodeValid } />
                <TextInput label="Mot de passe*" value={ password } onChange={ setPassword } valid={ passwordValid } type="password" />
                <TextInput label="Confirmation du mot de passe*" value={ confirmationPassword } onChange={ setConfirmationPassword } valid={ confirmationPasswordValid } type="password" />
            </div>
        }

        <div className="flex justify-end">

            <button type="button" className="hover:cursor-pointer hover:underline" onClick={ props.onLogin }>Se connecter ?</button>
        </div>

        <p className="text-xs text-gray-500">Les champs sont obligatoires*</p>

        <div className="flex justify-end pt-4">

            <SendFormButton onClick={ handleResetPassword } label="Réinitialiser le mot de passe" sent={ false } sending={ isSending } />
        </div>
    </form>);
};

export default ResetPasswordForm;