import React, {FormEvent, useState} from "react";
import {useSelector} from "react-redux";
import store from "../../store/Store";
import SendFormButton from "../SendFormButton/SendFormButton";
import ImageSelector from "../ImageSelector/ImageSelector";
import ImagePrevisualizer from "../ImagePrevisualizer/ImagePrevisualizer";
import {
    closeGalleryImagesAddingDialog,
    selectGalleryImages,
    selectIsGalleryImagesAddingDialogOpen,
    setGalleryImages
} from "../../store/Gallery/GallerySlice";
import {galleryService} from "../../service/GalleryService/GalleryService";
import {Image} from "../../model/Image/Image";

/**
 * Composant permettant de représenter la dialog d'ajout
 * d'images dans la galerie.
 *
 * @constructor
 */
const GalleryFormDialog = () => {

    const [images, setImages] = useState<File[]>([]);

    const [imagesValid, setImagesValid] = useState(true);

    const [isSending, setIsSending] = useState(false);

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const isOpen = useSelector(selectIsGalleryImagesAddingDialogOpen);

    const galleryImages = useSelector(selectGalleryImages);

    /**
     * Méthode permettant de fermer la dialog.
     */
    const handleClosing = () => {

        store.dispatch(
            closeGalleryImagesAddingDialog()
        );

        resetFormContent();
        resetFormValidation();
        setIsSending(false);
    };

    /**
     * Méthode permettant de gérer les erreurs.
     */
    const handleError = () => {

        setErrorMessage("Une erreur est survenue, veuillez réessayer.");
        setShowErrorMessage(true);
    }

    const resetFormContent = () => {

        setImages([]);
    };

    const resetFormValidation = () => {

        setImagesValid(true);
    };

    /**
     * Méthode permettant de valider le formulaire
     * d'ajout d'images.
     */
    const validateForm = () => {

        let isValid = true;

        if (isImagesEmpty(images)) {

            setImagesValid(false);
            isValid = false;
        }

        return isValid;
    };

    /**
     * Méthode permettant de savoir si des images ont
     * été sélectionnées ou non.
     *
     * @param images les images à vérifier.
     */
    const isImagesEmpty = (images: File[]) => {

        return images === null || images.length === 0;
    };

    const handleAddImages = (event: FormEvent) => {

        event.preventDefault();

        const isFormValid = validateForm();

        if (isFormValid) {

            setIsSending(true);

            const formData = new FormData();
            for (let image of images) {

                formData.append("imagesFiles", image);
            }

            galleryService.addImages(formData)
                .then((response: Image[]) => {

                    const concatenatedGalleryImages = [
                        ...galleryImages,
                        ...response
                    ];

                    store.dispatch(
                        setGalleryImages(concatenatedGalleryImages)
                    );

                    handleClosing();
                })
                .catch(() => {

                    resetFormValidation();
                    setIsSending(false);
                    handleError();
                });
        }
    };

    /**
     * Méthode permettant de gérer ce qui se passe à la suppression d'une
     * image dans le visualiseur.
     *
     * @param index index de l'image concernée par la suppression.
     */
    const handleImageDeletion = (index: number) => {

        if (index >= 0) {

            const filteredImages = images.filter((_, i) => i !== index)

            setImages([...filteredImages]);
        }
    };

    return (<div>{
        isOpen &&
        <div className="fixed inset-0 z-50 flex flex-col p-6 justify-center bg-white md:items-center">

            <div className="md:w-1/2 2xl:w-1/3">

                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-semibold">Ajouter des images</h2>
                </div>

                {
                    showErrorMessage &&
                    <div className="border rounded-md border-red-500 bg-red-100 p-2 text-red-600">
                        { errorMessage }
                    </div>
                }

                <div className="flex flex-col space-y-2">

                    <ImageSelector label="Images*" value={ images } onChange={ setImages } valid={ imagesValid } />
                    <ImagePrevisualizer images={ images } onImageDeletion={ handleImageDeletion } />
                </div>

                <p className="text-xs text-gray-500">Les champs sont obligatoires*</p>

                <div className="mt-4 flex justify-end space-x-2">
                    <SendFormButton onClick={ handleAddImages } label="Ajouter" sending={ isSending } />
                    <button className="border rounded-md py-2 px-4 hover:border-gray-300 hover:bg-gray-50" onClick={ handleClosing }>Annuler</button>
                </div>
            </div>
        </div>
    }</div>)
};

export default GalleryFormDialog;